import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";
import logo from "../img/signature-solid-navy.svg";
import * as ContentComponents from "../components/ContentComponents";
import useSiteMetadata from "../components/SiteMetadata";

export const FeaturePageTemplate = ({
  subtitle,
  html,
  htmlComponent,
  contentComponent,
  componentData,
}) => {
  const ContentComponent =
    contentComponent && ContentComponents[contentComponent];

  const HTMLComponent = htmlComponent || Content;

  return (
    <>
      <section className="container mt-2 md:mt-4 lg:mt-8 space-y-4">
        <div className="columns">
          <div className="w-full lg:w-10/12 lg:pr-12">
            <h2 className="font-display font-bold text-3xl lg:text-4xl text-accent-m1 leading-tight">
              {subtitle}
            </h2>
          </div>
        </div>

        <div className="columns">
          <div className="w-full lg:w-10/12">
            <HTMLComponent
              className="prose lg:prose-lg max-w-none"
              content={html}
            />
          </div>
        </div>
      </section>

      {ContentComponent && <ContentComponent {...componentData} />}
    </>
  );
};

const FeaturePage = ({ data }) => {
  const { markdownRemark: post } = data;

  // TODO: DRY-out og:image component
  const { siteUrl } = useSiteMetadata();

  return (
    <Layout
      headerClassName="bg-accent curve-br"
      navbarLogo={logo}
      className={post.frontmatter.accentClass}
      headerTitle={post.frontmatter.heading}
    >
      <Helmet titleTemplate="%s - ScriptDrop">
        <title>{`${post.frontmatter.title}`}</title>
        <meta property="og:title" content={post.frontmatter.title} />
        <meta
          property="og:description"
          content={post.frontmatter.description}
        />
        <meta property="description" content={post.frontmatter.description} />
        {post.frontmatter.shareImage && (
          <meta
            property="og:image"
            content={siteUrl.concat(
              post.frontmatter.shareImage.childImageSharp
                ? post.frontmatter.shareImage.childImageSharp.shareImage.src
                : post.frontmatter.shareImage.publicURL
            )}
          />
        )}
      </Helmet>

      <FeaturePageTemplate
        subtitle={post.frontmatter.subtitle}
        html={post.html}
        htmlComponent={HTMLContent}
        contentComponent={post.frontmatter.contentComponent}
        componentData={post.frontmatter}
      />
    </Layout>
  );
};

export default FeaturePage;

export const pageQuery = graphql`
  query featurePageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        heading
        subtitle
        accentClass
        contentComponent
        shareImage {
          publicURL
          childImageSharp {
            shareImage: resize(width: 1200, height: 630, fit: COVER) {
              src
            }
          }
        }
      }
    }
  }
`;
